<template>
  <div class="user-modal">
    <el-dialog
      width="50%"
      center
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <div class="user-modal-buttons">
        <el-button
          class="btn-secondary-app"
          :loading="loading"
          type="info"
          @click="action('userDataForm')"
          @keypress="action('userDataForm')"
          >{{ state === 'create' ? 'Create' : 'Save' }}
        </el-button>
      </div>

      <div class="content-modal-user scroll-gray">
        <el-form
          label-position="top"
          :model="userDataForm"
          status-icon
          ref="userDataForm"
          label-width="120px"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          :rules="rules">

          <el-form-item label="Avatar">
            <custom-upload v-model="userDataForm.profilePicture"></custom-upload>
          </el-form-item>

          <el-form-item label="First Name" prop="firstname">
            <el-input
              type="text"
              v-model="userDataForm.firstname"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="Last Name" prop="lastname">
            <el-input
              type="text"
              v-model="userDataForm.lastname"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="Email" prop="email">
            <el-input
              type="email"
              v-model="userDataForm.email"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item v-if="state === 'create'" label="Password" prop="password">
            <el-input
              :type="showPassword"
              v-model="userDataForm.password"
              autocomplete="off">
              <el-button
                @click="handleShowPassword"
                slot="append"
                icon="el-icon-view">
              </el-button>
            </el-input>
          </el-form-item>

          <el-form-item label="Organization" prop="organization">
            <el-input
              type="organization"
              v-model="userDataForm.organization"
              autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="Applications" prop="applications">
            <el-select
              v-model="userDataForm.applications"
              filterable
              multiple
              placeholder="Select"
              @remove-tag="handleRemoveApplication">
              <el-option
                v-for="item in applications"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Roles" prop="roles">
            <el-select
              filterable
              v-model="userDataForm.roles"
              multiple
              @remove-tag="handleRemoveRole"
              placeholder="Select applications">
              <el-option
                v-for="item in rolesApplications"
                :key="item.roleId"
                :label="`${item.role} - (${item.application})`"
                :value="item.roleId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiSSO from '@/lib/ApiSSO';
import CustomUpload from '@/components/Uploads/CustomUpload.vue';

export default {
  name: 'UserModal',
  props: ['showModal', 'title', 'state', 'id', 'brand'],
  components: {
    CustomUpload,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password'));
      } else {
        // Check if password is at least 12 characters long
        if (value.length < 12) {
          callback(new Error('Check if password is at least 12 characters long'));
        }

        // Check if password contains at least one lowercase letter
        if (!/[a-z]/.test(value)) {
          callback(new Error('Check if password contains at least one lowercase letter'));
        }

        // Check if password contains at least one uppercase letter
        if (!/[A-Z]/.test(value)) {
          callback(new Error('Check if password contains at least one uppercase letter'));
        }

        // Check if password contains at least one number
        if (!/[0-9]/.test(value)) {
          callback(new Error('Check if password contains at least one number'));
        }

        // Check if password contains at least one special character
        if (!/[\W]/.test(value)) {
          callback(new Error('Check if password contains at least one special character'));
        }

        callback();
      }
    };
    return {
      showPassword: 'password',
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      userDataForm: {
        active: 1,
        applications: [],
        newRoles: [],
      },
      rolesCopy: [],
      applications: [],
      rolesApplications: [],
      rules: {
        firstname: [
          {
            type: 'string',
            required: true,
            message: 'Please enter name.',
            trigger: 'blur',
          },
        ],
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter email.',
            trigger: 'blur',
          },
        ],
        password: [
          {
            validator: validatePass,
            required: true,
            trigger: 'blur',
          },
        ],
        applications: [
          {
            type: 'array',
            required: true,
            message: "Please enter application's.",
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
    // eslint-disable-next-line.
    /* eslint-disable */
    'userDataForm.applications': {
      handler: async function (newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.rolesApplications = Object.values(await this.getRolesByApps());
        }
      },
      deep: true
    },
    // eslint-disable-next-line.
    /* eslint-disable */
    'userDataForm.roles': {
      handler: async function (newVal, oldVal) {
        if (!Array.isArray(newVal) || !Array.isArray(oldVal)) {
          return;
        }
        if (newVal.length !== oldVal.length) {
          this.userDataForm.newRoles = this.rolesApplications.filter(r => {
            if (this.userDataForm.roles.includes(r.roleId)) {
              return r;
            }
          }).map(item => item);
        }
      },
      deep: true
    },
  },
  methods: {
    handleShowPassword() {
      if (this.showPassword === 'password') {
        this.showPassword = 'text';
      } else {
        this.showPassword = 'password';
      }
    },
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getUser() {
      try {
        const r = await ApiSSO.get(`/users/${this.id}`);
        if (r.success) {
          this.userDataForm = { ...r.data, newRoles: [] };
          if (!this.userDataForm.roles || this.userDataForm.roles == null) {
            this.userDataForm.roles = [];
          } else if (typeof this.userDataForm.roles === 'string') {
            this.userDataForm.roles = this.userDataForm.roles.split(',');
          }
          if (this.userDataForm.applications) {
            this.userDataForm.applications = this.userDataForm.applications.split(',');
            this.userDataForm.roles = this.userDataForm.roles;
            this.rolesCopy = Object.values(await this.getRolesByApps());
            this.userDataForm.newRoles = this.rolesCopy.filter(r => {
              if (this.userDataForm.roles.includes(r.roleId)) {
                return r;
              }
            }).map(item => item);
          }
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    handleRemoveRole(roleId) {
      const deletedIndex = this.userDataForm.newRoles.findIndex(obj => {
        return obj.roleId === roleId
      });
      if (deletedIndex > -1) {
        this.userDataForm.newRoles.splice(deletedIndex, 1);
      }
    },
    async sendCreate() {
      try {
        const r = await ApiSSO.post('/users', this.userDataForm);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('userDataForm');
          this.$root.$emit('reloadDataUsers');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.userDataForm.id = this.id;
        const r = await ApiSSO.put('/users', this.userDataForm);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('userDataForm');
          this.$root.$emit('reloadDataUsers');
          this.rolesCopy = [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getApplications() {
      ApiSSO.get('/applications').then((r) => {
        if (r.success) {
          this.applications = Object.values(r.data);
        }
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
      });
    },
    async getRolesByApps() {
      try {
        const r = await ApiSSO.post('/roles/applications', this.userDataForm.applications);
        if (r.success) {
          return r.data;
        }
      } catch (e) {
        console.error(e);
        return [];
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    handleRemoveApplication(applicationId) {
      this.rolesApplications = this.rolesCopy.filter(r => {
        return r.applicationId !== applicationId;
      }).map(item => item);
      const roles = this.userDataForm.roles;

      this.userDataForm.roles = this.rolesApplications.filter(r => {
        if (roles.includes(r.roleId)) {
          return r;
        }
      }).map(item => item.roleId);
    }
  },
  mounted() {
    this.getApplications();
    if (this.state === 'update') {
      this.getUser();
    }
  },
};
</script>

<style lang="scss">
// .user-modal {
//   .el-form--label-top .el-form-item__label {
//     padding: 0 !important;
//   }

//   .el-form-item {
//     display: flex;
//     width: 100%;
//     justify-content: right;
//     gap: 20px;
//     // grid-template-columns: 80%;
//     // justify-content: right;
//     @media (max-width: 768px) {
//       display: block;
//     }
//   }

//   .el-form-item__content {
//     width: 68%;
//     margin-right: 0;
//     @media (max-width: 768px) {
//       width: 100%;
//     }
//   }

//   .el-form-item__label {
//     font-family: 'Work Sans';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     color: #3C3A3A;
//     letter-spacing: -0.01em;
//   }

//   .content-modal-user {
//     height: 500px;
//     overflow-y: scroll;
//     padding: 0 20px 0 0;
//     @media (max-width: 424px) {
//       height: 400px;
//     }
//   }

//   .el-dialog {
//     padding: 40px 50px;
//     width: 80% !important;
//     border-radius: 8px;
//     height: auto;
//     margin-top: 8vh !important;

//     @media (min-width: 720px) {
//       width: 40% !important;
//     }
//     @media (max-width: 768px) {
//       padding: 30px 20px;
//     }

//     .el-dialog__header {
//       padding-top: 0;

//       .el-dialog__title {
//         font-family: 'Work Sans' !important;
//         display: flex;
//         align-self: left;
//       }
//     }

//     .el-dialog__body {
//       padding: 0;
//     }
//   }

//   .btn-secondary-app {
//     width: 134px !important;
//     height: 36px !important;
//   }
// }

.user-modal {

max-width: 585px;

.content-modal-user {
  height: 500px;
  overflow-y: auto;
  padding: 0 20px 0 0;
  @media (max-width: 424px) {
    height: 400px;
  }
}

.user-modal-buttons {
  @media (max-width: 424px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
  }
}

.content-buttons {
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.el-dialog {
  padding: 40px 50px;
  width: 80% !important;
  border-radius: 8px;
  height: auto;
  margin-top: 8vh !important;

  @media (min-width: 720px) {
    width: 40% !important;
  }
  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  .el-dialog__header {
    padding: 10px 0 !important;
    text-align: justify;

    .el-dialog__title {
      font-family: 'Work Sans' !important;
    }
  }

  .el-dialog__body {
    padding: 0;
  }
}

}
</style>
